import React from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const Natura = () => {
  return (
    <React.Fragment>
      <GatsbySeo
        title="Calcolatore di emissioni di CO2"
        description="Il calcolatore ti permette di calcolare le tue emissioni di CO2 e ti aiuta a capire ed analizzare i tuoi dati"
        canonical="https://www.casafraine.com/calcolo-immissioni-di-CO2"
        openGraph={{
          url: "https://www.casafraine.com/calcolo-immissioni-di-CO2",
          title: "Calcolatore di emissioni di CO2",
          description:
            "Il calcolatore ti permette di calcolare le tue emissioni di CO2 e ti a aiuta a capire ed analizzare i tuoi dati",

          images: [
            {
              url: "https://www.casafraine.com/mapitaly.png",
              width: 800,
              height: 600,
              alt: "Calcolatore di emissioni di CO2",
            },
            {
              url: "https://www.casafraine.com/mapitaly.png",
              width: 900,
              height: 800,
              alt: "Calcolatore di emissioni di CO2",
            },
            { url: "https://www.casafraine.com/mapitaly.png" },
            { url: "https://www.casafraine.com/mapitaly.png" },
          ],
          site_name: "Casa Fraine",
        }}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />
      <section className="booking">
        <h2>Calcolo emissioni di CO2</h2>
        <center>
          <iframe
            src="https://www.footprintcalculator.org/"
            width="1125"
            height="850"
            frameBorder="0"
            scrolling="no"
            allowFullScreen={true}
            loading="lazy"
          ></iframe>
        </center>
      </section>

      <section className="booking">
        <h2>Osservazione Dati</h2>
        <center>
          <iframe
            src="https://data.footprintnetwork.org/#/"
            width="1125"
            height="2250"
            frameBorder="0"
            scrolling="no"
            allowFullScreen={true}
            loading="lazy"
          ></iframe>
        </center>
      </section>
    </React.Fragment>
  );
};

export default Natura;
